(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name person.information.controller:InformationCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.home.persons.person.information')
    .controller('InformationPersonCtrl', InformationPersonCtrl);

  function InformationPersonCtrl($scope,$mdToast, $state, $filter, $stateParams, townHall, licenseTeamPerson,
                                 licenseTypes, genders, Person, categories, LicenseTeamPerson, sports, currentSeason, team, ActionSendEmailEes,foto) {
    var vm = this;
    
 
  
  $scope.image = null;
  $scope.imageFileName = '';
  
  $scope.uploadme = {};
  $scope.uploadme.src = '';
  
  
    vm.ctrlName = 'InformationPersonCtrl';
   
    $scope.image=foto[0];
    $scope.DISABLEBUTTON = false;
   
    vm.updatePerson = updatePerson;

    var findItemById = function (collection, itemId) {
      return _.find(collection, function (i) {
        return i.id === itemId;
      });
    };

    vm.person = buildPersonModel(licenseTeamPerson, licenseTypes);
    vm.cards = [
      {
        name: licenseTeamPerson.person.name,
        surname: licenseTeamPerson.person.surname + (
          licenseTeamPerson.person.secondSurname !== null ? licenseTeamPerson.person.secondSurname : ''),
        birthday: moment(licenseTeamPerson.person.bornDate).format('L'),
        entity: licenseTeamPerson.organisationName,
        team: licenseTeamPerson.teamName,
        category: licenseTeamPerson.teamCategoryName,
        townHall: townHall.name,
        licenseCode: licenseTeamPerson.licenceCode
      }
    ];

    vm.team = buildTeamModel(licenseTeamPerson);
    vm.personGenders = _.filter(genders, function (g) {
      return g.person;
    });
    vm.personCategory = undefined;

    var personBornYear = moment(vm.person.bornDate).year();
    angular.forEach(categories, function (category) {
      if (personBornYear >= category.startYear && personBornYear <= category.endYear) {
        vm.personCategory = category;
      }
    });

    vm.cards = [
      {
        name: licenseTeamPerson.person.name,
        surname: licenseTeamPerson.person.surname+' ' + (
          licenseTeamPerson.person.secondSurname !== null ? licenseTeamPerson.person.secondSurname : ''),
        personCategory: vm.personCategory.name,
        birthday: moment(licenseTeamPerson.person.bornDate).format('L'),
        entity: licenseTeamPerson.organisationName,
        team: licenseTeamPerson.teamName,
        teamCategory: licenseTeamPerson.teamCategoryName,
        sport: findItemById(sports, team.sportId).name,
        licenceType: findItemById(licenseTypes, licenseTeamPerson.licenceTypeId).name,
        townHall: townHall.name,
        licenseCode: licenseTeamPerson.licenceCode,
        season: {
          initDate: moment(currentSeason.iniDate).format('YYYY'),
          endDate: moment(currentSeason.endDate).format('YYYY')
        }
      }
    ];


    vm.edit = false;
    if (licenseTeamPerson.ees) {
      vm.buttons = {
        dial: true,
        icon: 'more_vert',
        tooltip: 'ACTIONS',
        actions: [{
          icon: 'edit',
          tooltip: 'EDIT',
          execute: enableEdit
        }, {
          icon: 'print',
          tooltip: 'PRINT',
          execute: printCard
        }, {
          icon: 'delete',
          attrClass: 'md-warn',
          tooltip: 'DELETE',
          execute: deleteLicence
        }, {
          buttonImage: 'images/ic_send_black_48px.svg',
          attrClass: '',
          tooltip: $filter('translate')('ESCOLA_EMAIL'),
          execute: resendEmailEes
        }]
      };
    }
    else {
      vm.buttons = {
        dial: true,
        icon: 'more_vert',
        tooltip: 'ACTIONS',
        actions: [{
          icon: 'edit',
          tooltip: 'EDIT',
          execute: enableEdit
        }, {
          icon: 'print',
          tooltip: 'PRINT',
          execute: printCard
        }, {
          icon: 'delete',
          attrClass: 'md-warn',
          tooltip: 'DELETE',
          execute: deleteLicence
        }]
      };
    }

    vm.disableEdit = function () {
      vm.edit = false;
      vm.person = buildPersonModel(licenseTeamPerson, licenseTypes);
      vm.person.foto=$scope.image;
      vm.buttons.dial = true;
    };

    function deleteLicence() {
      LicenseTeamPerson.remove({licenseId: $stateParams.license, teamId: $stateParams.team}, function () {
          $mdToast.show(
            $mdToast.simple()
              .textContent($filter('translate')('PEOPLE.SUCCESSFUL_DELETE'))
              .position('bottom left')
              .hideDelay(3000));
          $state.go('home.persons');
        },
        function (err) {
          var message = 'PEOPLE.' + err.data.message || 'PEOPLE.ERROR_DELETE';
          $mdToast.show(
            $mdToast.simple()
              .textContent($filter('translate')(message))
              .position('bottom left')
              .hideDelay(3000)
          );
        });
    }

    function buildPersonModel(licenseTeamPerson, licenseTypes) {
      var personModel = _.cloneDeep(licenseTeamPerson.person);
      personModel.licenceCode = licenseTeamPerson.licenceCode;
      personModel.bornDate = moment(personModel.bornDate).toDate();
      personModel.licenseType = $filter('translate')('PERSON_TYPES.' + findItemById(licenseTypes, licenseTeamPerson.licenceTypeId).name);
      
      return personModel;
    }

    function buildTeamModel(licenseTeamPerson) {
      var teamModel = {};
      teamModel.organisationName = licenseTeamPerson.organisationName;
      teamModel.info = $filter('translate')(licenseTeamPerson.programmeName) + " - " +
        $filter('translate')('CATEGORIES.' + licenseTeamPerson.teamCategoryName) + " - " +
        $filter('translate')(licenseTeamPerson.teamName);
      return teamModel;
    }

    function enableEdit() {
      vm.edit = true;
      vm.buttons.dial = false;
    }

    function printCard() {
      setTimeout(function () {
        var template = document.getElementById('cardsPrintPreview').innerHTML;
        var printWin = window.open('', '',
          'left=0, top=0, width=' + screen.availWidth + ', height=' + screen.availHeight
          + ', toolbar=0, scrollbars=0, status=0');
        printWin.document.write(template);
        printWin.document.close();
        setTimeout(function () {
          printWin.focus();
          printWin.print();
          printWin.close();
        }, 250);
      }, 250);
    }

    function preservesCategory(date) {
      var newYear = moment(date).year();
      if (newYear < vm.personCategory.startYear || newYear > vm.personCategory.endYear) {
        showToast('BORN_YEAR_AND_CATEGORY_TEAM_INCOMPATIBLE');
        return false;
      }
      return true;
    }

    function showToast(dateError) {
      $mdToast.show(
        $mdToast.simple()
          .textContent($filter('translate')(dateError))
          .position('bottom left')
          .hideDelay(3000));
    }

    var parseDate = function (updatedPerson) {
      return moment(updatedPerson.bornDate).format("YYYY-MM-DD");
    };

    function updatePerson() {
       
      var updatedPerson = angular.copy(vm.person);
      if (preservesCategory(updatedPerson.bornDate)) {
        updatedPerson.bornDate = parseDate(updatedPerson);
        updatedPerson.foto=document.getElementById('im').src;
        delete updatedPerson.id;
        Person.update({personId: vm.person.id}, updatedPerson, function () {
            $state.go('home.persons', {}, {reload: true});
            showToast('PEOPLE.SUCCESSFUL_UPDATE');
          },
          function (err) {
            if (err.status === 400) {
              showToast('BAD_REQUEST_PERSON');
            } else if (err.status === 409) {
              // TODO: Whats happen if the person already exists?
              showToast('PEOPLE.EXISTING_PERSON');
            } else {
              showToast('SERVER_ERROR');
            }
          });
      }
    }

    function resendEmailEes() {
      if (!vm.person.email) {
        alert("es obligatori l'email de l'esportista per poder fer la invitació ");
      }
      else {
        ActionSendEmailEes.get({id: vm.person.id, ido: licenseTeamPerson.organisationId},
          function () {
            $mdToast.show(
              $mdToast.simple()
                .textContent($filter('translate')('SUCCESSFUL_EMAIL_RESEND'))
                .position('bottom left')
                .hideDelay(3000)
            );
          }, function (error) {
            var message = null;
            if (error.status === 409) {
              message = 'ALREADY_REGISTERED_USER_AND_NOT_RESEND';
            } else {
              message = 'ERROR_SENDING_MAIL';
            }
            $mdToast.show(
              $mdToast.simple()
                .textContent($filter('translate')(message))
                .position('bottom left')
                .hideDelay(3000)
            );
          });
      }
    }
  }
}());
